import io from 'socket.io-client';
const SOCKET_URL = 'https://aws.xolanihealth.cloud';
// const SOCKET_URL = 'https://server.xolanihealth.cloud';
//const SOCKET_URL = 'http://localhost:3005';
class SocketService {
  constructor() {
    this.socket = null;
    this.emittedEvents = [];
  }

  connect(token) {
    if (!this.socket) {
      this.socket = io.connect(SOCKET_URL, {
        query: { token },
        transports: ['websocket'],
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        'force new connection': true,
        autoconnect: true,
        secure: true,
      });

      this.socket.on('connect_error', (error) => {
        console.error('Socket connection error:', error);
      });

      this.socket.on('reconnect', () => {
        console.log('Reconnected to socket server');
      });

      this.socket.on('reconnect_attempt', () => {
        console.log('Attempting to reconnect...');
      });

      this.socket.on('reconnect_error', (error) => {
        console.error('Socket reconnection error:', error);
      });

      this.socket.on('reconnect_failed', () => {
        console.error('Socket reconnection failed');
      });

      this.socket.on('connect', () => {
        this.isConnected = true;
        if (this.socket?.recovered) {
          console.log('Recovered connection to socket server');
        } else {
          console.log('Connected to socket server');
        }
        this.reEmitEmittedEvents();
      });
    }
  }

  id() {
    return this.socket.id;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  readyState() {
    return this.socket && this.socket.readyState;
  }

  close() {
    return this.socket.close();
  }

  isConnected() {
    return this.socket && this.socket.connected;
  }

  on(event, callback) {
    if (this.socket) {
      this.socket.on(event, callback);
    }
  }

  off(event, callback) {
    if (this.socket) {
      this.socket.off(event, callback);
    }
  }

  emit(event, data, callback) {
    if (this.socket) {
      this.socket.emit(event, data, callback);
      console.log(`Emitted event: ${event}, data:`, data);
      this.storeEmittedEvent(event, data, callback);
    }
  }

  storeEmittedEvent(event, data, callback) {
    const exists = this.emittedEvents.some(
      (emitted) =>
        emitted.event === event &&
        JSON.stringify(emitted.data) === JSON.stringify(data) &&
        emitted.callback === callback,
    );
    if (!exists) {
      this.emittedEvents.push({ event, data, callback });
    }
  }

  reEmitEmittedEvents() {
    this.emittedEvents.forEach(({ event, data, callback }) => {
      this.socket.emit(event, data, callback);
      console.log(`Re-emitted event: ${event}, data:`, data);
    });
  }
}

const socketService = new SocketService();
export default socketService;
